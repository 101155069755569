<!-- 预览 -->
<template>
  <div class="topic-details">
    <!-- 题 -->
    <el-card class="active" ref="barparent">
      <el-steps :active="active" finish-status="success">
        <el-step v-for="(item,i) in taskAmount" :key="i"></el-step>
      </el-steps>

      <el-card class="box-card-left">
        <el-scrollbar style="height:100%">
          <div>
            <span v-if="false">题目描述</span>
            <ul>
              <li v-for="(item,i) in descriptions" :key="i">
                {{descriptionType[item.descriptionType]}}
                <b v-html="item.description" class="des"></b>
              </li>
            </ul>
          </div>
        </el-scrollbar>
      </el-card>
      <el-card class="box-card-right">
        <el-scrollbar style="height:100%">
          <div v-for="(item,i) in questions" :key="i">
            <span>
              {{item.sn}}
              <i class="qtype">[ {{questionsType[item.qtype]}} ]</i>
            </span>
            <p v-html="item.stemContent" class="stemContent"></p>
            <div class="choices">
              <span
                :class="completionStatus? v.userChoice==1 ? 'text-option class3':'text-option':(selecteObj[item.id]==null?null:(selecteObj[item.id][v.id]))==1 ? 'text-option class3':'text-option'"
                v-for="(v,index) in item.choices"
                :key="index"
              >
                {{v.sn}}
                <i v-html="v.choiceContent" class="choices"></i>
              </span>
              <span
                :class="item.userAnswerResult == 0?'answer green':'answer red'"
                v-if="completionStatus"
              >
                {{userAnswerResultType[item.userAnswerResult]}}
                <i
                  class="answerChoices"
                >正确答案:{{item.answer.toString().replace(/,/g, '、')}}</i>
              </span>
            </div>
          </div>
        </el-scrollbar>
      </el-card>
    </el-card>
    <div :class="this.$store.state.toggle_collapsed?'activity-button wth':'activity-button w100'">
      <el-button type="primary" style="padding-right: 14px;" @click="nextSubject">下一题</el-button>
      <el-button @click="edit">退出</el-button>
    </div>
  </div>
</template>

<script>
export default {
  props: ['yuLanObj'],
  data () {
    return {
      params: {},
      active: 0,
      taskAmount: null,
      count: 0,
      time: '00:00',
      descriptions: [],
      questions: [],
      selected: false,
      selecteObj: {},
      answer: {},
      dialogVisible: false,
      knowledge: [],
      totalTime: 0,
      descriptionType: this.parameter.descriptionType(),
      questionsType: {
        2: '单选题',
        3: '多选题'
      },
      userAnswerResultType: {
        0: '您的答案正确!',
        1: '您的答案错误!'
      },
      showButton: true,
      showNext: false,
      subjectId: 0,
      completionStatus: false,
      isComplete: 1,
    }
  },

  components: {},
  created () {
    this.params = {
      "activeId": null,
      "caseId": this.yuLanObj.caseIds[0],
      "caseType": 0,  //0：k12 1:immx
      "isComplete": 1,// 1, 是否完成0 没有 1 有
      "taskId": null,//  任务id
    }
    this.getData(this.params)
  },
  methods: {
    edit () {
      this.$emit('editOut', '')
    },
    getData (params) {
      this.$api.page.getCase(params).then((res) => {
        if (res.data.data) {
          this.descriptions = res.data.data.descriptions;
          this.questions = res.data.data.questions;
          //处理正确答案
          for (let i = 0; i < this.questions.length; i++) {

            this.questions[i].choices = this.questions[i].choices.sort((a, b) => {
              return (a.sn + '') > (b.sn + '') ? 1 : -1;
            })
            this.questions[i].answer = this.questions[i].choices.map((a, i) => {
              let str = null
              a.isAnswer == 1 ? str = a.sn : str = null
              return str;
            })
            this.questions[i].answer = this.questions[i].answer.filter((item) => {
              return item !== null
            })
          }
        }

      });
    },
    // 下一题
    nextSubject () {
      this.subjectId = this.subjectId + 1
      let active = this.yuLanObj.caseIds.length;
      if (this.subjectId < active) {
        this.params = {
          "activeId": null,
          "caseId": this.yuLanObj.caseIds[this.subjectId],
          "caseType": 0,  //0：k12 1:immx
          "isComplete": 1,// 1, 是否完成0 没有 1 有
          "taskId": null,//  任务id
        }
        this.getData(this.params)
      } else {
        this.$message.error('已经是最后一题了!');
      }
    },
  }
}

</script>
<style lang='less' scoped>
@import '../../style/subject/subject.less';
@import '../../style/mouse.less';
.class3 {
  background: #d8e8ff;
  box-shadow: 0px 2px 4px 0px #dcedff;
}
.class4 {
  background: green;
  box-shadow: 0px 2px 4px 0px #dcedff;
}
.des /deep/ p {
  text-indent: 1em;
  line-height: 35px;
  font-size: 12px;
  font-weight: 400;
  color: #323232;
}
.des/deep/img {
  vertical-align: text-top;
}

.stemContent/deep/img {
  vertical-align: middle;
}
.choices/deep/p {
  display: inline-block;
  & img {
    height: 100%;
    vertical-align: middle;
  }
}
.activity-button {
  width: 100% !important;
}
</style>

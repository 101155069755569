<!-- 新建活动弹窗 -->
<template>
  <div>
    <!-- 创建活动弹窗 -->
    <el-dialog
      title="创建新的教学活动"
      :visible.sync="centerDialogVisible"
      width="60%"
      :before-close="handleClose"
    >
      <el-form
        :model="ruleForm"
        :rules="rules"
        ref="ruleForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <el-form-item label="活动标题：" prop="activityTitle" class="input">
          <el-input
            v-model="ruleForm.activityTitle"
            placeholder="2-50个字符"
            maxlength="50"
            show-word-limit
            size="mini"
            width="10%"
          ></el-input>
        </el-form-item>
        <el-form-item label="活动类型：" prop="activityValue" class="select">
          <el-select
            v-model="ruleForm.activityValue"
            placeholder="请选择"
            size="mini"
            @change="choiceActivity"
          >
            <el-option
              v-for="item in activityType"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="Thespecialvariant" prop="cascader" class="select">
          <el-cascader
            :options="variantType"
            v-model="ruleForm.cascader"
            :props="props"
            clearable
            size="mini"
            ref="cascader"
            v-if="showCascader && showlabel"
            :show-all-levels="false"
            @change="node($event)"
            @visible-change="elCascaderOnlick"
            @expand-change="elCascaderOnlick"
          ></el-cascader>
          <!-- 标签的题库 -->
          <el-select
            v-model="ruleForm.cascader"
            multiple
            size="mini"
            v-if="!showlabel"
            placeholder="请选择题库"
          >
            <el-option
              v-for="item in resourcecatalog"
              :key="item.id"
              :label="item.name"
              :value="item.id"
              size="mini"
            ></el-option>
          </el-select>
          <el-cascader
            :options="variantType"
            v-model="ruleForm.cascader"
            :show-all-levels="false"
            v-if="!showCascader && showlabel"
            size="mini"
          ></el-cascader>
        </el-form-item>
        <el-form-item label="开始日期：" prop="startDate" class="block">
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="ruleForm.startDate"
            type="date"
            placeholder="选择日期"
            size="mini"
          ></el-date-picker>
        </el-form-item>
        <!-- //标签模板 -->
        <el-form-item v-if="!showlabel" label="标签模板：" class="select" prop="pitchonLabel">
          <el-select
            v-model="ruleForm.pitchonLabel"
            size="mini"
            placeholder="请选择"
            @change="Selectthelabelval"
          >
            <el-option
              v-for="item in Thelabeltemplate"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>

        <el-form-item label="结束日期：" prop="endDate" class="block">
          <el-date-picker
            value-format="yyyy-MM-dd HH:mm:ss"
            v-model="ruleForm.endDate"
            type="date"
            placeholder="选择日期"
            size="mini"
          ></el-date-picker>
        </el-form-item>
        <el-form-item label="活动启用：" class="switch">
          <el-switch
            v-model="ruleForm.showSwitch"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="activeState($event)"
            :disabled="showOpan"
          ></el-switch>
          {{ state[0] }}
        </el-form-item>

        <!-- showlabel 是  为k12 -->
        <el-form-item class="activity-type" v-if="showlabel">
          <el-checkbox v-model="ruleForm.checked" @change="showType">互动活动</el-checkbox>
          <el-radio
            v-model="ruleForm.radio"
            label="1"
            :disabled="showRadio"
            @change="showRadioType"
          >PK活动</el-radio>
          <el-radio
            v-model="ruleForm.radio"
            label="2"
            :disabled="showRadio"
            @change="showRadioType"
          >帮扶活动</el-radio>
        </el-form-item>
        <!-- <div  v-if="!showlabel">我是标签下的东西</div> -->
        <el-form-item label="是否紧急：" class="switch" v-if="showlabel">
          <el-switch
            v-model="ruleForm.urgent"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @change="activeUrgent($event)"
          ></el-switch>
          {{ state[1] }}
        </el-form-item>
        <el-form-item label="活动描述：" prop="textDescribe" class="textarea">
          <el-input
            type="textarea"
            v-model="ruleForm.textDescribe"
            placeholder="1-200个字符"
            maxlength="200"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item label="活动指导：" prop="textGuidance" class="textarea">
          <el-input
            type="textarea"
            v-model="ruleForm.textGuidance"
            placeholder="1-200个字符"
            maxlength="200"
            show-word-limit
          ></el-input>
        </el-form-item>
        <el-form-item class="classification" @change="choiceRadio" v-if="showTransfer">
          选择参与学生：
          <el-radio v-model="ruleForm.radioParticipate" label="1" @change="choiceRadio">按姓名</el-radio>
          <el-radio v-model="ruleForm.radioParticipate" label="2" @change="choiceRadio">按群组</el-radio>
        </el-form-item>
        <el-form-item class="transferValue" v-if="showTransfer">
          <el-transfer :titles="['未选中', '已选中']" v-model="ruleForm.transferValue" :data="data"></el-transfer>
        </el-form-item>
        <el-form-item
          label="帮扶人："
          v-show="!showTransfer"
          class="helpType"
          v-for="(item, index) in helpTypeOptionsForm"
          :key="index"
        >
          <el-select
            v-model="item.helpType"
            placeholder="请选择"
            @change="choiseHelp(index, item.helpType)"
          >
            <el-option
              v-for="itm in item.helpTypeOptions"
              :key="itm.key"
              :label="itm.label"
              :value="itm.key"
              :disabled="itm.disabled"
            ></el-option>
          </el-select>
          <span class="span-help">被帮扶人：</span>
          <el-select
            v-model="item.behelpType"
            placeholder="请选择"
            @change="choiseHelp(index, item.behelpType)"
          >
            <el-option
              v-for="itm in item.behelpTypeOptions"
              :key="itm.key"
              :label="itm.label"
              :value="itm.key"
              :disabled="itm.disabled"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button @click="addDomain" v-if="!showTransfer" type="primary">新增帮扶小组</el-button>
          <el-button @click="removeDomain" v-if="!showTransfer" type="danger">删除帮扶小组</el-button>
        </el-form-item>
        <el-form-item class="el-form-button">
          <span class="span-button">
            <el-button type="primary" @click="createActivity('ruleForm')">提 交</el-button>
            <el-button plain @click="preview('ruleForm')">预览</el-button>
            <el-button @click="cancel">取 消</el-button>
          </span>
        </el-form-item>
      </el-form>
      <!-- k12预览弹窗 -->
      <div class="showPreview" v-if="showPreview && ruleForm.activityValue == 0">
        <previewSubject
          @editOut="
              () => {
                this.showPreview = false;
              }
            "
          :yuLanObj="yuLanObj"
        ></previewSubject>
      </div>
      <!-- immex预览弹窗 -->
      <div class="showPreview" v-if="showPreview && ruleForm.activityValue == 1">
        <previewImmex
          @editOut="
              () => {
                this.showPreview = false;
              }
            "
          :yuLanObj="yuLanObj"
        ></previewImmex>
      </div>
      <!-- 标签预览弹窗 -->
      <div class="showPreview" v-if="showPreview && ruleForm.activityValue == 2">
        <previewLabel
          @editOut="
              () => {
                this.showPreview = false;
              }
            "
          :yuLanObj="yuLanObj"
        ></previewLabel>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { getSpecialtopic, GetStudentByGradeClass, activeAdd } from "../../api/api";
import previewSubject from '../previewSubject/index.vue'
export default {
  props: {
    show_addActivity: {
      type: Boolean
    },
    addActivityObj: {
      type: Object
    },
    class_info: {
      type: Object
    }
  },
  data () {
    let validateStartTime = (rule, value, callback) => {
      if (!value) {
        callback(new Error("请选择开始时间"));
      } else {
        if (this.ruleForm.endDate) {
          this.$refs.ruleForm.validateField("endDate");
        }
        callback();
      }
    };
    let validateEndTime = (rule, value, callback) => {
      if (!value) {
        callback();
      } else {
        if (!this.ruleForm.startDate) {
          callback(new Error("请选择开始时间！"));
        } else if (Date.parse(this.ruleForm.startDate) >= Date.parse(value)) {
          callback(new Error("结束时间必须大于开始时间！"));
        } else {
          callback();
        }
      }
    };
    return {
      centerDialogVisible: false,
      ruleForm: {
        activityTitle: null,
        cascader: null,
        activityValue: null,
        startDate: null,
        endDate: "",
        showSwitch: true,
        checked: false,
        radio: "0",
        textDescribe: null,
        textGuidance: null,
        transferValue: [],
        radioParticipate: "1",
        urgent: false,
        pitchonLabel: null, //选中的标签
      },
      rules: {
        activityTitle: [
          { required: true, message: "请填写活动标题", trigger: "blur" },
          {
            min: 2,
            max: 50,
            message: "长度在 2 到 50 个字符",
            trigger: "blur",
          },
        ],
        activityValue: [
          { required: true, message: "请选择活动类型", trigger: "change" },
        ],
        cascader: [
          { required: true, message: "请选择专题变式", trigger: "change" },
        ],
        pitchonLabel: [
          { required: true, message: "请选择标签模板", trigger: "change" },
        ],
        referenceversion: [
          { required: true, message: "请选择参考答案", trigger: "change" },
        ],
        startDate: [
          {
            required: true,
            validator: validateStartTime,
            trigger: "blur",
          },
        ],
        endDate: [
          {
            required: false,
            validator: validateEndTime,
            trigger: "blur",
          },
        ],
        textDescribe: [
          { required: true, message: "请填写活动描述", trigger: "blur" },
        ],
        textGuidance: [
          { required: true, message: "请填写活动指导", trigger: "blur" },
        ],
      },
      activityType: [], //活动类型
      Thespecialvariant: "专题变式：", //专题变式
      showCascader: true,
      showlabel: true, //是否显示不选中标签的内容
      variantType: [],
      props: { multiple: true },
      showOpan: false,
      state: {
        0: "启用",
        1: "已关闭",
      },
      showRadio: true,
      showTransfer: true,
      data: [],
      helpTypeOptionsForm: [
        {
          helpTypeOptions: [],
          key: 1,
          helpType: null,
          behelpTypeOptions: [],
          behelpType: null,
        },
      ],
      showPreview: false,
      helpStudentData: [],
      cascadeData: [],
      studentData: [],
      groupData: [],
      showCurrent: true,
      throttle: true,
      difficulty: [],//选中的难度字段
    }
  },
  watch: {
    show_addActivity (val, old) {
      this.centerDialogVisible = true
    },
    addActivityObj (val, old) {
      if (val !== old) {
        this.fast_create()
      }
    },
    class_info (val, old) {
      if (val !== old) {
        this.loadStudent(val.id)
      }
    },
    variantType (val, old) {
      if (val !== old) {
        if (val.length > 0) {
          let obj = { data: null }
          let first_obj = this.variantType.find(item => { return item.id == this.addActivityObj.sectionId })
          obj.data = first_obj.children.find(item => { return item.id == this.addActivityObj.topic })
          this.difficulty.push(obj)
        }

      }
    }
  },
  components: {
    previewSubject
  },
  mounted () {
    this.analysis()
  },
  methods: {
    // 触发快速创建活动
    fast_create () {
      this.cascadeData = []
      this.addActivity();
      this.ruleForm.activityValue = "0";
      this.choiceActivity(0);
      this.ruleForm.radioParticipate = "1";
      this.choiceRadio(1);
      this.ruleForm.transferValue = this.addActivityObj.userId
        .split(",")
        .map(Number);
      let section_arr = [];
      let cases_arr = [];
      section_arr.push(this.addActivityObj.sectionId);
      section_arr.push(this.addActivityObj.topic);
      this.cascadeData.push(this.addActivityObj.topic);
      cases_arr.push(section_arr);
      this.ruleForm.cascader = cases_arr;

    },
    resourcecatalog1 (val) { },
    //标签选中
    Selectthelabelval (val) {
      // template_id 标签模板id
      this.Selectthelabel.template_id = val;
    },
    // 解析appIds
    analysis () {
      if (this.utils.identity('K12做题') || this.utils.identity('IMX做题')) {
        this.status = 1
      }
      if (this.utils.identity('新建标签活动')) {
        this.activityType.push({
          label: "标签",
          value: "2",
        });
      }
      if (this.utils.identity('标签活动列表详情')) {
        this.TABactivitylistdetails = "1";
      }
      if (this.utils.identity('新建K12活动')) {
        this.activityType.push({
          label: "k12",
          value: "0",
        });
      }
      if (this.utils.identity('新建IMMEX活动')) {
        this.activityType.push({
          label: "IMMEX",
          value: "1",
        });
      }
    },
    // 帮扶选中
    choiseHelp (index, id) {
      let arr = [];
      this.helpTypeOptionsForm.map((itm) => {
        if (itm.helpType !== null) {
          arr.push(itm.helpType);
        }
        if (itm.behelpType !== null) {
          arr.push(itm.behelpType);
        }
      });
      this.helpStudentData.map((item) => {
        item.disabled = false;
        arr.map((itm) => {
          if (item.id == itm) {
            item.disabled = true;
          }
        });
      });
      this.helpTypeOptionsForm.map((item) => {
        item.helpTypeOptions = this.helpStudentData;
        item.behelpTypeOptions = this.helpStudentData;
      });
    },
    // 动态增加下拉框
    addDomain () {
      if (
        this.helpTypeOptionsForm.length <
        parseInt(this.helpStudentData.length / 2)
      ) {
        this.helpTypeOptionsForm.push({
          helpTypeOptions: this.helpStudentData,
          behelpTypeOptions: this.helpStudentData,
          key: Date.now(),
        });
      } else {
        this.$message({
          message: "剩余学生数量不支持帮扶!",
          type: "warning",
        });
      }
    },
    // 动态删除下拉框
    removeDomain () {
      if (this.helpTypeOptionsForm.length !== 1) {
        let i = this.helpTypeOptionsForm.length - 1;
        let arr = [];
        arr.push(this.helpTypeOptionsForm[i].behelpType);
        arr.push(this.helpTypeOptionsForm[i].helpType);
        this.helpStudentData.map((item) => {
          arr.map((itm) => {
            if (item.id == itm) {
              item.disabled = false;
            }
          });
        });
        this.helpTypeOptionsForm.splice(i, 1);
      } else {
        this.$message({
          message: "至少保留一组帮扶学生!",
          type: "warning",
        });
      }
    },
    searchClassText (val) {
      this.classRoomText = val;
    },
    // 设置点击文本选中
    elCascaderOnlick () {
      setTimeout(function () {
        document.querySelectorAll(".el-cascader-menu").forEach((el, index) => {
          if (index == 1) {
            el.querySelectorAll(".el-cascader-node__label").forEach((el1) => {
              el1.onclick = function () {
                this.previousElementSibling.click();
              };
            });
          }
        });
      }, 100);
    },
    node (val) {
      if (this.cascadeData.length > 0) {
        this.cascadeData.map((item) => {
          val.map((itm) => {
            if (item !== itm[1]) {
              this.cascadeData.push(itm[1]);
              this.cascadeData = [...new Set(this.cascadeData)];
            }
          });
        });
        let arr = [];
        let arr1 = [];
        this.cascadeData.map((item) => {
          val.map((itm) => {
            if (item == itm[1]) {
              arr.push(itm[1]);
            }
          });
          this.ruleForm.cascader.map((it) => {
            if (item == it[1]) {
              arr1.push(it);
            }
          });
        });
        this.cascadeData = arr;
        this.ruleForm.cascader = arr1;
      } else {
        this.cascadeData.push(val[0][1]);
      }
      this.difficulty = this.$refs['cascader'].getCheckedNodes()
    },
    // 开启状态
    activeState (val) {
      if (!val) {
        this.state[0] = "停止";
      } else {
        this.state[0] = "启用";
      }
    },
    // 开启状态
    activeUrgent (val) {
      if (!val) {
        this.state[1] = "已关闭";
        this.showOpan = false;
      } else {
        this.state[1] = "已开启";
        this.state[0] = "启用";
        this.showOpan = true;
        this.ruleForm.showSwitch = true;
      }
    },
    // 搜索传来的值
    classData (val) {
      let a = val.find((item) => {
        return item.id == this.$route.query.classId;
      });
      this.activityTree = a;
      this.classOptions = val;
    },
    // 获取班级
    loadClass () {
      classList({}).then((res) => {
        let a = res.data.list.find((item) => {
          return item.id == this.$route.query.classId;
        });
        this.activityTree = a;
        this.classOptions = res.data.list;
      });
    },
    // 获取当前班级下的学生
    loadStudent (val) {
      this.studentData = []
      var isUser = 1;
      if (this.TABactivitylistdetails) {
        //有为1，代表教授
        isUser = 2;
      }
      GetStudentByGradeClass({ classId: val, isUser: isUser }).then((res) => {
        res.data.list.map((item) => {
          item["key"] = item.id;
          item["label"] = item.fullName;
        });
        this.studentData = res.data.list;
        this.helpStudentData = res.data.list;
        this.helpTypeOptionsForm[0].helpTypeOptions = this.helpStudentData;
        this.helpTypeOptionsForm[0].behelpTypeOptions = this.helpStudentData;
      });
    },
    // 判断活动类型
    showType () {
      this.showRadio = !this.showRadio;
      this.showTransfer = true;
      this.helpStudentData.map((item) => {
        item.disabled = false;
      });
      if (this.showRadio) {
        this.ruleForm.radio = "0";
      } else {
        this.ruleForm.radio = "1";
      }
    },
    showRadioType (val) {
      if (val == 2) {
        this.helpStudentData.map((item) => {
          item.disabled = false;
        });
        this.helpTypeOptionsForm[0].helpTypeOptions = this.helpStudentData;
        this.helpTypeOptionsForm[0].behelpTypeOptions = this.helpStudentData;
        this.showTransfer = false;
      } else {
        this.helpTypeOptionsForm = [
          {
            helpTypeOptions: [],
            key: 1,
            helpType: null,
            behelpTypeOptions: [],
            behelpType: null,
          },
        ];
        this.helpStudentData.map((item) => {
          item.disabled = false;
        });
        this.showTransfer = true;
      }
    },
    // 新增活动获取数据
    addActivity () {
      // this.ruleForm.radioParticipate = '1'
      this.variantType = []
      this.ruleForm.activityTitle = null;
      this.ruleForm.cascader = null
      this.ruleForm.showSwitch = true;
      this.ruleForm.textDescribe = null;
      this.ruleForm.textGuidance = null;
      this.showPreview = false;
      this.centerDialogVisible = true;
      this.choiceRadio(1);
    },
    // 创建活动选中类型
    choiceActivity (val) {
      this.ruleForm.cascader = null;
      let param = {};
      //0是k12
      if (val == 0) {
        this.showlabel = true; //显示互动
        this.Thespecialvariant = "专题变式：";
        this.showCascader = true;
        param = {
          grade: this.class_info.grade,
          subject: this.class_info.subjects,
          specialtopicType: Number(val),
        }; //获取k12的数据
        getSpecialtopic(param).then((res) => {
          if (this.showCascader) {
            //当前是创建k12的
            res.data.map((item) => {
              item["label"] = item.chapterTitle;
              item["children"] = item.npCases;
              item.children.map((itm) => {
                itm["label"] = itm.caseSN;
              });
            });
            let arr = [];
            res.data.forEach((item) => {
              if (item.npCases.length > 0) {
                item["value"] = item.id;
                item.children.map((itm) => {
                  itm["value"] = itm.id;
                  itm.children = undefined;
                });
                arr.push(item);
              }
            });
            this.variantType = arr;
            //k12清空表单
            this.ruleForm.pitchonLabel = null;
          } else {
            res.data.map((item) => {
              item["label"] = item.psname;
              item["children"] = item.caseEntities;
              item.children.map((itm, index) => {
                itm["label"] = item.psname + itm.caseSN;
              });
            });
            let arr = [];
            res.data.forEach((item) => {
              item["value"] = item.id;
              item.children.map((itm) => {
                itm["value"] = itm.id;
                itm.children = undefined;
              });
              arr.push(item);
            });
            this.variantType = arr;
          }
        });
      } else {
        this.Thespecialvariant = "专题变式：";
        this.showlabel = true; //显示互动
        this.showCascader = false;
        param = {
          grade: this.class_info.grade,
          subject: this.class_info.subjects,
          // specialtopicType: Number(val),
        };
        getIMXTask(param).then(res => {
          if (res.code == 0) {
            res.data.map((item) => {
              item["label"] = item.chapterName;
              item["children"] = item.list;
              item.children.map((itm, index) => {
                itm["label"] = item.chapterName + itm.caseName;
              });
            });
            let arr = [];
            res.data.forEach((item) => {
              item["value"] = item.id;
              item.children.map((itm) => {
                itm["value"] = itm.id;
                itm.children = undefined;
              });
              arr.push(item);
            });
            this.variantType = arr;
          }
        })
      }
      if (val == 2) {
        //选择了标签  this.searchId.id班级id
        this.Thespecialvariant = "选择题库：";
        this.showlabel = false; //显示标签下的所有内容
        //获取标签模板列表  需要传入当前年级和科目
        templateList({
          gradeId: this.class_info.grade,
          subjectId: this.class_info.subjects,
        }).then((res) => {
          this.Thelabeltemplate = res.data.list;
        });
        resourceList({
          gradeId: this.class_info.grade,
          subjectId: this.class_info.subjects,
        }).then((res) => {
          //获取题库资源列表   需要传入当前年级和科目
          this.resourcecatalog = res.data.list;
        });
      }
    },
    // 提交表单
    createActivity (formName) {
      // 帮扶数据
      let arr1 = null;
      let arr = [];
      let userArr = [];
      if (this.ruleForm.radio == 2) {
        let arr2 = [];
        this.helpTypeOptionsForm.forEach((item) => {
          let obj = {};
          obj["userId"] = item.helpType;
          obj["beUserId"] = item.behelpType;
          arr2.push(obj);
          userArr.push(item.helpType);
          userArr.push(item.behelpType);
        });
        arr1 = arr2;
      }
      // 判断是否完善帮扶人信息
      if (arr1 !== null) {
        arr1.map((item) => {
          if (item.beUserId == null || item.userId == null) {
            this.showCurrent = false;
          } else {
            this.showCurrent = true;
          }
        });
      }
      this.ruleForm.showSwitch ? (this.showSwitch = 1) : (this.showSwitch = 0);
      // 判断题型并拿出caseId
      if (Number(this.ruleForm.activityValue) == 0) {
        if (this.ruleForm.cascader) {
          this.ruleForm.cascader.map((item, index) => {
            let obj = {};
            obj['difficulty'] = this.difficulty.find(difficulty_item => { return difficulty_item.data.id == this.cascadeData[index] }).data.difficulty
            obj["caseId"] = this.cascadeData[index];
            obj["sort"] = index + 1;
            obj["stemType"] = Number(this.ruleForm.activityValue);
            arr.push(obj);

          });
        }
      } else if (Number(this.ruleForm.activityValue) == 1) {
        let obj = {};
        obj["caseId"] = this.ruleForm.cascader[1];
        obj["sort"] = 1;
        obj["stemType"] = Number(this.ruleForm.activityValue);
        arr.push(obj);
      } else if (Number(this.ruleForm.activityValue) == 2) {
        //是标签
        this.ruleForm.cascader.map((item, index) => {
          let obj = {};
          obj["caseId"] = this.ruleForm.cascader[index];
          obj["sort"] = index + 1;
          obj["templateId"] = this.ruleForm.pitchonLabel;
          obj["stemType"] = Number(this.ruleForm.activityValue);
          arr.push(obj);
        });
      }
      let a = {
        activityTitle: null,
        cascader: null,
        activityValue: null,
        startDate: null,
        endDate: "",
        showSwitch: true,
        checked: false,
        radio: "0",
        textDescribe: null,
        textGuidance: null,
        transferValue: [],
        radioParticipate: "2",
        urgent: false,
      };
      if (this.ruleForm.transferValue.length == 0) {
        this.$message.error("请选择参与学生");
        return;
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          // 判断是否紧急
          if (this.ruleForm.urgent) {
            this.ruleForm.urgent = "1";
          } else {
            this.ruleForm.urgent = "0";
          }
          let i = [];
          // 获取小组的学生
          this.groupData.map((item) => {
            this.ruleForm.transferValue.map((itm) => {
              if (item.id == itm) {
                item.list.map((it) => {
                  i.push(it.id);
                });
              }
            });
          });
          if (i.length !== 0) {
            i = [...new Set(i)];
            this.ruleForm.transferValue = i;
          }
          if (this.ruleForm.radio == 2) {
            this.ruleForm.transferValue = userArr;
          }
          let param = {
            //需要新加进去
            endTime: this.ruleForm.endDate, //结束时间--
            isStart: Number(this.showSwitch), //是否开启0：关闭 1：开启
            startTime: this.ruleForm.startDate, //开始时间
            description: this.ruleForm.textDescribe, //活动描述
            teachingMessage: this.ruleForm.textGuidance, //活动指导
            stems: arr,
            title: this.ruleForm.activityTitle, //活动标题
            type: Number(this.ruleForm.radio), //活动类型（0：一般；1：pk；2：帮扶）
            priority: Number(this.ruleForm.urgent), //优先级别（0：一般；1：高）
            userIds: this.ruleForm.transferValue,
            stemType: Number(this.ruleForm.activityValue), //题库类型  0: k12  1: immx, 2:标签
            classId: this.class_info.id, //班级id
            supportList: arr1, //帮扶配置
            templateId: this.ruleForm.pitchonLabel, //标签模板id----------------------------
            record_id: "", //标签记录id
          };
          if (this.showCurrent && this.throttle) {
            this.throttle = false;
            activeAdd(param).then((res) => {
              if (res.code == 0) {
                this.ruleForm = a;
                this.showRadio = true;
                this.showTransfer = true;
                this.helpStudentData.map((item) => {
                  item.disabled = false;
                });
                this.helpTypeOptionsForm = [
                  {
                    helpTypeOptions: [],
                    key: 1,
                    helpType: null,
                    behelpTypeOptions: [],
                    behelpType: null,
                  },
                ];
                this.$message({
                  message: "创建活动成功",
                  type: "success",
                });
                this.centerDialogVisible = false;
                this.throttle = true;
                let userId = Number(this.utils.userId())
                _hmt.push(['_trackEvent', '一键布置活动', '创建活动', '活动名:' + this.ruleForm.activityTitle, userId]);
              } else {
                this.$message.error(res.message);
              }
            });
          } else {
            this.$message({
              message: "请完善信息！",
              type: "warning",
            });
          }
        } else {
          return false;
        }
      });
    },
    // 新建活动选中群组
    choiceRadio (val) {
      if (val == 2) {
        this.data = this.groupData;
        this.ruleForm.transferValue = [];
      }
      if (val == 1) {
        this.data = this.studentData;
        this.ruleForm.transferValue = [];
      }
    },
    // 选择班级渲染群组
    choiceSelect (val) {
      groupList({ classId: val, pageNum: null, pageSize: 5 }).then((res) => {
        res.data.list.map((item) => {
          item["label"] = item.name + "(" + item.list.length + "人)";
          item["key"] = item.id;
        });
        this.groupData = res.data.list;
      });
    },
    // 排序
    compare (property) {
      return function (a, b) {
        return a[property] - b[property];
      };
    },
    // 关闭二次确认
    handleClose (done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          this.showOpan = false;
          done();
          this.cancel();
          this.helpStudentData.map((item) => {
            item.disabled = false;
          });
        })
        .catch((_) => { });
    },
    // 取消创建活动重置状态
    cancel () {
      // this.showlabel = true; //显示互动
      this.Thelabeltemplate = [];
      this.centerDialogVisible = false;
      let a = {
        activityTitle: null,
        cascader: null,
        activityValue: null,
        startDate: null,
        endDate: "",
        showSwitch: true,
        checked: false,
        radio: "0",
        textDescribe: null,
        textGuidance: null,
        transferValue: [],
        radioParticipate: "2",
        urgent: false,
        pitchonLabel: null, //选中的标签 pitchonLabel
      };
      this.ruleForm = a;
      this.state[0] = "启用";
      this.state[1] = "停止";
      this.showTransfer = true;
      this.showRadio = true;
      this.showOpan = false;
      this.helpTypeOptionsForm = [
        {
          helpTypeOptions: [],
          key: 1,
          helpType: null,
          behelpTypeOptions: [],
          behelpType: null,
        },
      ];
      this.helpStudentData.map((item) => {
        item.disabled = false;
      });
    },
    preview (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let caseIds = "";
          if (this.ruleForm.activityValue == 0) {
            caseIds = this.cascadeData;
          } else {
            caseIds = this.ruleForm.cascader[1];
          }
          this.yuLanObj = {
            caseIds: caseIds,
          };
          if (this.ruleForm.activityValue == 2) {
            //是标签
            this.yuLanObj = {
              caseIds: this.ruleForm.cascader,
              labelId: this.Selectthelabel.template_id,
            };
          }
          this.showPreview = true;
        } else {
          return false;
        }
      });
    },
  }
}

</script>
<style lang='less' scoped>
@import '../../style/mouse.less';
.el-dialog {
  padding: 20px;
  .input {
    margin-bottom: 20px;
    display: inline-block;
    width: 50%;

    .el-input {
      width: 60%;
    }
  }

  .select {
    margin-bottom: 20px;
    display: inline-block;
    width: 50%;

    .el-cascader {
      width: 60%;
    }

    .el-select {
      width: 60%;
    }
  }

  .block {
    margin-bottom: 20px;
    display: inline-block;
    width: 50%;

    .el-date-editor {
      width: 60%;
    }
  }

  .switch {
    margin-bottom: 20px;
    display: inline-block;
    width: 50%;
  }

  .activity-type {
    margin-bottom: 20px;
    display: inline-block;
    width: 50%;
  }

  .textarea {
    margin-bottom: 20px;
    display: block;
    line-height: 55px;

    .el-textarea {
      width: 80%;
    }
  }

  .classification {
    margin-bottom: 20px;
    display: block;
  }

  .transfer {
    margin-bottom: 20px;
    display: block;

    .el-transfer {
      width: 70%;
      min-width: 750px;
      margin: 0 auto;
    }
  }

  .el-transfer-panel {
    width: 280px;
  }

  .transferValue {
    .el-form-item__content {
      position: relative;
      margin-left: 0 !important;
    }
  }

  .el-form-button {
    .el-form-item__content {
      position: relative;
      margin-left: 0 !important;
      .span-button {
        position: absolute;
        left: 42%;
        transform: translate(-50%, -50%);
      }
    }
  }

  .el-transfer {
    position: relative;
    width: 744px;
    left: 50%;
    transform: translate(-50%);
  }

  .demo-ruleForm {
    position: relative;
  }
}

.showPreview {
  z-index: 100;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.el-form-item {
  padding-left: 60px;
}

.helpType {
  .span-help {
    display: inline-block;
    margin-left: 20px;
  }

  .el-form-item {
    display: inline-block;
  }

  /deep/.el-form-item__content {
    display: inline-block;
    margin-left: 0;
  }
}

.el-cascader-menu__wrap {
  height: 400px;
  margin-bottom: 0 !important;
}
</style>
